
@mfeedbackType.length > 0edia (min-width: 1940px);
body {
  background-color: white !important;

  #app {
    margin-left: 0%; } }

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }


.DUOC-Background-color {
  background-color: #1a1a1a !important;
  color: White !important;

  p h1 {
    background: #0db7b4;
    color: white !important; }
  div {
    p h1 {
      background: #0db7b4;
      color: white !important; } } }

.video-container-iframe iframe {
  width: 100% !important;
  height: 100% !important;
  min-height: 413px; }

.video-container-iframe-small iframe {
  width: 100% !important;
  height: 100% !important; }

//----
 *////* styles.css */

/* Contenedor oscuro que cubre toda la pantalla */
.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; }



/* Caja principal de la alerta */
.alert-box {
  max-width: 800px;
  width: 90%;
  text-align: center;
  position: relative;

  margin: 45px 2px 40px 0;
  padding: 16.5px 16px 16px;
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.16);
  border: solid 1px #f24f4f;
  background-color: white; }


/* Encabezado: ícono + título */
.alert-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; }


/* Ícono de advertencia (rojo circular) */
.alert-icon {
  background-color: #f00;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; }


/* Título del aviso */
.alert-title {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase; }


/* Mensaje de la alerta */
.alert-message {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 15px; }


/* Enlace dentro del mensaje */
.alert-message a {
  color: #00a451;
  text-decoration: underline; }


/* Sección para el logo */
.alert-logo {
  width: 100%;
  height: 65px;
  object-fit: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: visible;
  margin-bottom: 32px;
  margin-top: 16px; }

.logo-c1 {
  width: 205px;
  height: 47px; }
/* Botones de acción */

.alert-buttons {
  display: flex;
  gap: 10px;
  justify-content: center; }


/* Estilo base para ambos botones */
.btn {
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease; }


/* Botón de Cerrar */
.close-btn {
  width: 188px;
  height: 26px;

  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16);
  background-color: #fff;
  border-radius: 0px;
  border-radius: 0px;
  font-family: TitilliumWeb;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000; }

/* Botón para ir al formulario (verde) */
.form-btn {
  width: 188px;
  height: 26px;
  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16);
  background-color: #5fcde9;

  border-radius: 0px;
  font-family: TitilliumWeb;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000; }

/* Hover en ambos botones */
.btn:hover {
  opacity: 0.8; }



//----
