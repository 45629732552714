@import url(https://fonts.googleapis.com/css?family=Ubuntu:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Biryani:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css?family=Biryani:100,300,400,500,700,800,400i);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400&display=swap);
.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.3); }

.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.4), 0 1px 18px 0 rgba(0, 0, 0, 0.2), 0 3px 5px -1px rgba(0, 0, 0, 0.3); }

.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.4), 0 3px 14px 2px rgba(0, 0, 0, 0.2), 0 5px 5px -3px rgba(0, 0, 0, 0.3); }

.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.4), 0 6px 30px 5px rgba(0, 0, 0, 0.2), 0 8px 10px -5px rgba(0, 0, 0, 0.3); }

* {
  box-sizing: border-box;
  font-family: 'Titillium Web' !important; }

tspan {
  font-family: 'Titillium Web', sans-serif !important; }

body, a {
  color: #0A0A0A; }

a {
  text-decoration: none;
  font-family: 'Titillium Web' !important;
  font-weight: 600; }

body {
  font-family: "Ubuntu";
  margin: 0;
  padding: 0;
  background-color: #f6f6f6; }

button {
  font-family: "Ubuntu";
  font-size: 1em;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

input {
  width: 100%;
  min-width: 0;
  border: none;
  outline: none;
  background: none; }

p {
  letter-spacing: 0.18px !important;
  font-family: 'Titillium Web' !important; }

h2 {
  font-weight: 400;
  font-family: 'Titillium Web' !important;
  letter-spacing: 0.23px !important; }

h3, h4 {
  letter-spacing: 0.18px !important; }

h5, h6 {
  letter-spacing: 0.1px !important; }

h1 {
  letter-spacing: 0px !important; }

h1, h3, h4, h5, h6 {
  font-weight: 600 !important;
  font-family: 'Titillium Web' !important; }

.button {
  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16); }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.card {
  border-radius: 3.6px;
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18);
  background-color: #ffffff; }

.course-card {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18); }

.course-card-large {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18); }

.image-status {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18) !important; }

.image {
  background-position: center;
  background-size: cover; }

.loading-container {
  text-align: center; }
  .loading-container .helper {
    display: inline-block;
    vertical-align: middle;
    height: 100%; }
  .loading-container img.loading {
    vertical-align: middle;
    width: 200px;
    height: 200px; }

.app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  .app > .header {
    height: auto;
    width: 100%; }
    .app > .header > .error-navbar {
      width: inherit;
      padding: 10px 10px 10px 30px;
      background-color: #fff;
      display: flex;
      flex-direction: row; }
      .app > .header > .error-navbar > .navbar-logo {
        width: 132px;
        height: 30px; }
      .app > .header > .error-navbar > a {
        width: 80px;
        height: auto;
        padding: 5px 0 5px 0; }
  .app > .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    padding: 70px 0px 0px 0px; }
    .app > .body > .title {
      font-family: Biryani !important;
      font-weight: bolder;
      font-size: 40px;
      line-height: 1.2; }
    .app > .body > span, .app > .body a {
      font-family: Ubuntu !important;
      font-size: 24px;
      font-weight: 400;
      text-align: center;
      line-height: 32px; }
    .app > .body > .text {
      margin: 30px 0 0 0; }
    .app > .body > a {
      color: #0191c1; }
    .app > .body > img {
      margin: 30px 0 0 0; }

@charset "UTF-8";
@mfeedbackType .length > 0edia (min-width: 1940px);
body {
  background-color: white !important; }
  body #app {
    margin-left: 0%; }

#app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }

.DUOC-Background-color {
  background-color: #1a1a1a !important;
  color: White !important; }
  .DUOC-Background-color p h1 {
    background: #0db7b4;
    color: white !important; }
  .DUOC-Background-color div p h1 {
    background: #0db7b4;
    color: white !important; }

.video-container-iframe iframe {
  width: 100% !important;
  height: 100% !important;
  min-height: 413px; }

.video-container-iframe-small iframe {
  width: 100% !important;
  height: 100% !important; }

*.alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; }

/* Caja principal de la alerta */
.alert-box {
  max-width: 800px;
  width: 90%;
  text-align: center;
  position: relative;
  margin: 45px 2px 40px 0;
  padding: 16.5px 16px 16px;
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.16);
  border: solid 1px #f24f4f;
  background-color: white; }

/* Encabezado: ícono + título */
.alert-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px; }

/* Ícono de advertencia (rojo circular) */
.alert-icon {
  background-color: #f00;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; }

/* Título del aviso */
.alert-title {
  font-size: 1.5em;
  font-weight: bold;
  text-transform: uppercase; }

/* Mensaje de la alerta */
.alert-message {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 15px; }

/* Enlace dentro del mensaje */
.alert-message a {
  color: #00a451;
  text-decoration: underline; }

/* Sección para el logo */
.alert-logo {
  width: 100%;
  height: 65px;
  object-fit: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: visible;
  margin-bottom: 32px;
  margin-top: 16px; }

.logo-c1 {
  width: 205px;
  height: 47px; }

/* Botones de acción */
.alert-buttons {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center; }

/* Estilo base para ambos botones */
.btn {
  border: none;
  padding: 10px 20px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease; }

/* Botón de Cerrar */
.close-btn {
  width: 188px;
  height: 26px;
  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16);
  background-color: #fff;
  border-radius: 0px;
  border-radius: 0px;
  font-family: TitilliumWeb;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000; }

/* Botón para ir al formulario (verde) */
.form-btn {
  width: 188px;
  height: 26px;
  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16);
  background-color: #5fcde9;
  border-radius: 0px;
  font-family: TitilliumWeb;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #000; }

/* Hover en ambos botones */
.btn:hover {
  opacity: 0.8; }

.z-depth-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.4), 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 1px -2px rgba(0, 0, 0, 0.2); }

.z-depth-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.4), 0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.3); }

.z-depth-3 {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.4), 0 1px 18px 0 rgba(0, 0, 0, 0.2), 0 3px 5px -1px rgba(0, 0, 0, 0.3); }

.z-depth-4 {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.4), 0 3px 14px 2px rgba(0, 0, 0, 0.2), 0 5px 5px -3px rgba(0, 0, 0, 0.3); }

.z-depth-5 {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.4), 0 6px 30px 5px rgba(0, 0, 0, 0.2), 0 8px 10px -5px rgba(0, 0, 0, 0.3); }

* {
  box-sizing: border-box;
  font-family: 'Titillium Web' !important; }

tspan {
  font-family: 'Titillium Web', sans-serif !important; }

body, a {
  color: #0A0A0A; }

a {
  text-decoration: none;
  font-family: 'Titillium Web' !important;
  font-weight: 600; }

body {
  font-family: "Ubuntu";
  margin: 0;
  padding: 0;
  background-color: #f6f6f6; }

button {
  font-family: "Ubuntu";
  font-size: 1em;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; }

input {
  width: 100%;
  min-width: 0;
  border: none;
  outline: none;
  background: none; }

p {
  letter-spacing: 0.18px !important;
  font-family: 'Titillium Web' !important; }

h2 {
  font-weight: 400;
  font-family: 'Titillium Web' !important;
  letter-spacing: 0.23px !important; }

h3, h4 {
  letter-spacing: 0.18px !important; }

h5, h6 {
  letter-spacing: 0.1px !important; }

h1 {
  letter-spacing: 0px !important; }

h1, h3, h4, h5, h6 {
  font-weight: 600 !important;
  font-family: 'Titillium Web' !important; }

.button {
  box-shadow: 0 4px 4px 0 rgba(68, 68, 68, 0.16); }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.card {
  border-radius: 3.6px;
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18);
  background-color: #ffffff; }

.course-card {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18); }

.course-card-large {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18); }

.image-status {
  box-shadow: 0 0 8px 0 rgba(136, 136, 136, 0.18) !important; }

